import { render, staticRenderFns } from "./AIDemoExternalCheckout.vue?vue&type=template&id=610a3268&scoped=true"
import script from "./AIDemoExternalCheckout.vue?vue&type=script&lang=js"
export * from "./AIDemoExternalCheckout.vue?vue&type=script&lang=js"
import style0 from "./AIDemoExternalCheckout.vue?vue&type=style&index=0&id=610a3268&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "610a3268",
  null
  
)

export default component.exports